import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react'
import { navigate } from 'gatsby'

// Create a context for language-related data and functions
export const LanguageContext = createContext()

// Define supported languages and default language
const supportedLanguages = ['lt', 'en'] // Add all supported languages
const defaultLanguage = 'en'

export const LanguageProvider = ({ children }) => {
  // Initialize language with a function to handle SSR
  const [language, setLanguage] = useState(() => {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname.split('/')
      return (path[1] && supportedLanguages.includes(path[1])) ? path[1] : defaultLanguage
    }
    return defaultLanguage
  })

  // Function to extract language from URL
  const getLanguageFromUrl = useCallback(() => {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname.split('/')
      return (path[1] && supportedLanguages.includes(path[1])) ? path[1] : defaultLanguage
    }
    return defaultLanguage
  }, [])

  // Effect to set initial language and handle route changes
  useEffect(() => {
    setLanguage(getLanguageFromUrl())

    // Update language when route changes (browser back/forward navigation)
    const handleRouteChange = () => setLanguage(getLanguageFromUrl())
    window.addEventListener('popstate', handleRouteChange)
    // Clean up event listener on component unmount
    return () => window.removeEventListener('popstate', handleRouteChange)
  }, [getLanguageFromUrl])

  // Compute language path prefix based on current language
  const langPath = useMemo(() => language === defaultLanguage ? '' : `/${language}`, [language])


  const [slugs, setSlugs] = useState({})
  // Function to change language and update URL
  const changeLanguage = useCallback((newLang, newSlugs) => {
    if (supportedLanguages.includes(newLang)) {
      setLanguage(newLang)
      setSlugs(newSlugs)
      
      let newPath = newSlugs[newLang]?.slug || ''
      if (newPath && !newPath.startsWith('/')) {
        newPath = '/' + newPath
      }
      
      const langPrefix = newLang === defaultLanguage ? '' : `/${newLang}`
      let fullPath = `${langPrefix}${newPath}`.replace('//', '/')
      
      // If switching to default language, remove the language prefix from the current path
      if (newLang === defaultLanguage) {
        const currentPath = typeof window !== 'undefined' ? window.location.pathname : ''
        fullPath = currentPath.replace(/^\/[a-z]{2}/, '') || '/'
      }
      
      navigate(fullPath)
    }
  }, [])

  // Memoized context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    language,
    changeLanguage,
    langPath,
    supportedLanguages,
    slugs,
    setSlugs
  }), [language, changeLanguage, langPath, slugs])

  // Provide the language context to child components
  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  )
}